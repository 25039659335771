<script lang="ts" setup>
definePageMeta({
  layout: 'default',
})

const menuItems = [
  {
    label: 'Vorteile',
    className: 'vorteile-section'
  },
  {
    label: 'Anleitung',
    className: 'anleitung-section'
  }
]

useHead({
  link: [
    {
      rel: 'canonical',
      href: `${useRequestURL().href}`
    }
  ]
})
</script>

<template lang="pug">
#index-page
  section.py-8.px-6(class="bg-slate-700 sm:py-12")
    .container.mx-auto
      ScrollMenu(:scroll-menu-items="menuItems")
      Hero

  section.py-12.px-6.bg-yellow-100
    .container.mx-auto
      OfficialCooperation
  
  section.py-24.px-6.bg-slate-50.vorteile-section
    .container.mx-auto
      h2.text-5xl.text-center.font-bold Die Vorteile

      Advantages.mt-12

  section.py-24.px-6.bg-slate-200.anleitung-section
    .container.mx-auto
      InstructionStep1

  section.py-24.px-6.bg-slate-50
    .container.mx-auto
      InstructionStep2
  
  section.py-24.px-6.bg-slate-200
    .container.mx-auto
      InstructionStep3

  section.py-24.px-6.bg-slate-50
    .container.mx-auto
      InstructionStep4

  section.py-24.px-3.bg-slate-50
    .container.mx-auto
      FaqSection

LayoutFooter

</template>

<style lang="sass">
@use '~/assets/css/instruction-step.sass'
</style>
